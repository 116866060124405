import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPolicyActionLinks,
  setHasOrderNotification,
  setIsTabActionsEnabled,
  setPolicyActionLinks,
} from "../../../store/slices/order";

export const LoadDealData = ({ documentNumber, orderId }) => {
  const dispatch = useDispatch();
  const policyActionData = useSelector(
    (state) => state.order?.policyActionsData
  );

  const policyURL = policyActionData?.links?.policyURL;

  useEffect(() => {
    dispatch(setHasOrderNotification(false));
    dispatch(setPolicyActionLinks({}));
    dispatch(setIsTabActionsEnabled(true));
    if (orderId && documentNumber && !policyURL) {
      dispatch(fetchPolicyActionLinks(documentNumber, orderId));
    }
  }, [orderId, documentNumber]);

  return null;
};
