import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  REGISTER_EXPRESS_ERRORS,
  FETCH_PAYMENTS_SUCCESS,
} from "appRedux/actionTypes";

//Components
import { Row, Col, Radio, Button, Icon, Alert } from "antd";

import Translation from "./translation";
//helpers
import axios from "axios";
import { displayError } from "helpers";

import { PaymentItems } from "./PaymentItems";

export const PaymentsRadio = (props) => {
  const { payments, handleChange, code, messages } = props;
  const [loaded, setLoaded] = useState(true);

  const dispatch = useDispatch();
  const errorMessages = useSelector((store) => store.crm.order.expressErrors);

  const [selected, setSelected] = useState(null);

  const onChange = (event) => {
    let value = event.target.value;
    setSelected(value);

    if (value) {
      handleChange({
        attr: "installment",
        value: value,
      });
    }
  };

  const getPaymentData = (successCallback) => {
    const url = `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/payment-data`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoaded(false);
    axios
      .get(url, config)
      .then((response) => {
        if (response.data) {
          if (response.data.payments) {
            dispatch({
              type: FETCH_PAYMENTS_SUCCESS,
              payload: response.data.payments,
            });
            setSelected(null);
          } else {
            dispatch({
              type: REGISTER_EXPRESS_ERRORS,
              expressErrors: response.data.message,
            });
            response.data.message.map((msg) => {
              if (msg !== "Not Found") {
                return displayError(msg);
              }
              return null;
            });
          }
        }
        setLoaded(true);
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: REGISTER_EXPRESS_ERRORS,
            expressErrors: [error.response.data.message],
          });
          displayError(error.response.data.message);
        }
        setLoaded(true);
      });
  };

  const getSelected = () => {
    if (!payments) {
      return null;
    }

    let payment = payments.filter((payment) => payment.selected);
    return payment[0] ? payment[0].installment : null;
  };

  useEffect(() => {
    setSelected(getSelected());
  }, []);

  return (
    <Radio.Group
      onChange={onChange}
      value={selected}
      className="payment-radio-group"
    >
      <Row gutter={12} className="payment-radio-group-header">
        <Col sm={9} md={9}>
          {Translation.paymentLabel}
        </Col>
        <Col sm={5} md={5} className="text-center">
          {Translation.installmentAmountLabel}
        </Col>
        <Col sm={5} md={5} className="text-center">
          {Translation.dueDateFirstInstallmentLabel}
        </Col>
        <Col sm={5} md={5} className="text-center">
          {Translation.totalAmountLabel}Total
        </Col>
      </Row>
      {payments !== null ? (
        <div className="payment-radio-group-list">
          <PaymentItems payments={payments} />
        </div>
      ) : (
        <>
          <Row gutter={12} className="payment-radio-group-header">
            <Button type="danger" onClick={getPaymentData}>
              {!loaded && <Icon type="loading" />}
              {Translation.getPaymentLabel}
            </Button>
          </Row>
          <Row gutter={12}>
            {errorMessages &&
              errorMessages.map((message) => {
                return (
                  <Col md={24}>
                    <Alert message={message} type="warning" />
                  </Col>
                );
              })}
          </Row>
        </>
      )}
    </Radio.Group>
  );
};
