import React, { Component, createRef } from "react";
//Translation
import Translation from "./translation";
//Components
import { Tabs } from "antd";
//Custom Components
import GeneralTab from "./tabs/General";
import DetailsTab from "./tabs/Details";
import SimulationsTab from "./tabs/Simulations";
import WhatsAppSender from "../../../WhatsAppTemplateComponents/WhatsAppSender";
import WhatsAppHistory from "../../../WhatsAppTemplateComponents/WhatsAppHistory";
//Common Components
import { InitialTab, InsuredTab, GoodsTab, UploadTab } from "../CommonTabs";
//Constants
const TabPane = Tabs.TabPane;

class CargoSingleBoarding extends Component {
  tabRefs = {
    goods: createRef(),
  };

  resetTabs = () => {
    const goodsReference = this.getReference("goods");
    if (goodsReference) goodsReference.reset();
  };

  notifyChanges = () => {
    const { modified, setModified } = this.props;
    if (!modified) setModified(true);
  };

  getReference = (referenceType) => {
    const tabReference = this.tabRefs[referenceType];
    return tabReference && tabReference.current;
  };

  getChanges = () => {
    const changes = {};

    const goodsReference = this.getReference("goods");

    if (goodsReference) changes.goods = goodsReference.getChanges();

    return changes;
  };

  render() {
    const {
      data,
      configurations,
      extraContent,
      handleCompanySearch,
      handleChange,
      errors,
      tabValue,
      superiorTabValue,
    } = this.props;

    return (
      <Tabs
        tabPosition="top"
        type="card"
        tabBarExtraContent={extraContent}
        onChange={this.props.changeSuperiorTab}
        activeKey={superiorTabValue}
      >
        <TabPane tab={Translation.applicationDataTabPaneLabel} key="1">
          <Tabs
            tabPosition="left"
            onChange={this.props.actualTab}
            activeKey={tabValue}
          >
            <TabPane tab={Translation.initialTabLabel} key={"initial"}>
              <InitialTab
                type="single"
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("initial")}
                configurations={configurations.initial}
                searchCompany={handleCompanySearch("initial")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.generalTabLabel} key="general">
              <GeneralTab
                configurations={configurations.general}
                handleChange={handleChange("general")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.goodsTabLabel} key="goods">
              <GoodsTab
                type="single"
                ref={this.tabRefs.goods}
                notifyChanges={this.notifyChanges}
                data={configurations.goods}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.insuredTabLabel} key={"insured"}>
              <InsuredTab
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("insured")}
                configurations={configurations.insured}
                searchCompany={handleCompanySearch("insured")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.detailsTabLabel} key="details">
              <DetailsTab
                configurations={configurations.details}
                handleChange={handleChange("details")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.uploadTabLabel} key="files">
              <UploadTab
                configurations={configurations.files}
                type={data.type}
                code={data.code}
                handleChange={handleChange("files")}
                fileExtensions={"pdf, jpg, png, bmp, tiff, xml"}
                OrderErrors={errors}
              />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab={Translation.simulationsTabLabel} key="simulations">
          <SimulationsTab data={data.simulations} />
        </TabPane>
        <TabPane tab={Translation.whatsAppLabel} key="2">
          <Tabs
            tabPosition="left"
            activeKey={tabValue}
            onChange={this.props.actualTab}
          >
            <TabPane tab={"chat"} key={"initial"}>
              <WhatsAppSender
                code={data.code}
                phoneNumber={configurations.initial.phoneNumber}
                activeKey={tabValue}
              />
            </TabPane>
            <TabPane tab={"Histórico de mensagens"} key={"history"}>
              <WhatsAppHistory code={data.code} activeKey={tabValue} />
            </TabPane>
          </Tabs>
        </TabPane>
      </Tabs>
    );
  }
}

export default CargoSingleBoarding;
