import React from "react";
// Components
import { Button, Tooltip } from "antd";
// Translation
import Translation from "./translation";
// new redux store
import { useSelector } from "react-redux";
//Custom Css
import "./Styles.less";

const orderActionTypes = {
  POLICY_ACTIONS: "POLICY_ACTIONS",
  DEFAULT: "DEFAULT",
};

const DefaultOrderActions = (props) => {
  const { modified, onReset, onSave } = props;
  return (
    <>
      {modified ? (
        <Tooltip title={Translation.resetChanges} placement="bottom">
          <Button
            onClick={onReset}
            className="gx-btn-outline-danger"
            htmlType="button"
          >
            {Translation.resetChanges}
          </Button>
        </Tooltip>
      ) : (
        <Button disabled={true} htmlType="button" className="orderActions-mr15">
          {Translation.resetChanges}
        </Button>
      )}
      {modified ? (
        <Tooltip title={Translation.saveChanges} placement="bottom">
          <Button
            onClick={onSave}
            className=" gx-btn-outline-success"
            htmlType="button"
          >
            {Translation.saveChanges}
          </Button>
        </Tooltip>
      ) : (
        <Button disabled htmlType="button">
          {Translation.saveChanges}
        </Button>
      )}
    </>
  );
};

const PolicyActions = ({ policyURL }) => {
  const handleRedirectToPolicy = () => {
    window.location.replace(policyURL);
  };

  return (
    <Button
      htmlType="button"
      className="gx-btn-primary"
      onClick={handleRedirectToPolicy}
    >
      Ver Apólice
    </Button>
  );
};

const OrderActions = (props) => {
  const { fetched, type, superiorTabValue } = props;
  const { links, isTabActionsEnabled } = useSelector(
    (state) => state.order?.policyActionsData
  );
  const policyURL = links?.policyURL;

  const suretyProposalTab = "6";
  const lifeProposalTab = "3";
  const cargoOpenPolicyProposalTab = "5";

  const propotalTabs = [
    suretyProposalTab,
    lifeProposalTab,
    cargoOpenPolicyProposalTab,
  ];

  const getCurrentActionType = () => {
    if (propotalTabs.includes(superiorTabValue) && isTabActionsEnabled)
      return null;
    if (policyURL) {
      return orderActionTypes.POLICY_ACTIONS;
    }

    return orderActionTypes.DEFAULT;
  };

  const configActions = {
    [orderActionTypes.POLICY_ACTIONS]: PolicyActions,
    [orderActionTypes.DEFAULT]: DefaultOrderActions,
  };

  if (fetched) {
    return (
      <section className="orderActions">
        {getCurrentActionType() !== null
          ? configActions[getCurrentActionType()]({
              policyURL,
              ...props,
            })
          : null}
      </section>
    );
  } else {
    return null;
  }
};

export default OrderActions;
