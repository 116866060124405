import { useDispatch } from "react-redux";
import { policyEmissionStatusEnum } from "../constants";
import { fetchSuretyPolicyEmissionStatus } from "../store/slices/emission/policy";
import { fetchPolicyActionLinks } from "../store/slices/order";

const usePullingEmissionStatusStrategy = () => {
  const dispatch = useDispatch();

  const pullingEmissionStatusHandler = (
    emissionStatus,
    code,
    documentNumber
  ) => {
    if (emissionStatus === policyEmissionStatusEnum.SURETY_PROCESSING) {
      const fetchData = async () => {
        dispatch(fetchSuretyPolicyEmissionStatus(code, documentNumber))
          .then((response) => {
            if (
              response?.data?.status === policyEmissionStatusEnum.SUCCESS ||
              response?.data?.status === policyEmissionStatusEnum.ERROR
            ) {
              clearInterval(fetchStatusInterval);
            }
          })
          .catch((err) => {
            clearInterval(fetchStatusInterval);
          });
      };

      const fetchStatusInterval = setInterval(() => {
        fetchData();
        console.log("[SURETY_PROCESSING]: Pulling policy emission status");
      }, 15000); // 15 seconds

      return () => clearInterval(fetchStatusInterval);
    } else if (emissionStatus === policyEmissionStatusEnum.AWS_PROCESSING) {
      const fetchData = async () => {
        dispatch(fetchSuretyPolicyEmissionStatus(code, documentNumber))
          .then((response) => {
            if (
              response?.data?.status === policyEmissionStatusEnum.SUCCESS ||
              response?.data?.status === policyEmissionStatusEnum.ERROR
            ) {
              clearInterval(fetchStatusInterval);
            }
          })
          .catch((err) => {
            clearInterval(fetchStatusInterval);
          });
      };

      const fetchStatusInterval = setInterval(() => {
        fetchData();
        console.log("[AWS_PROCESSING]: Pulling policy emission status");
      }, 1000 * 60 * 1 * 5); // 5 minutes

      return () => clearInterval(fetchStatusInterval);
    }
  };
  return { pullingEmissionStatusHandler };
};

export default usePullingEmissionStatusStrategy;
