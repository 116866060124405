import React from "react";

import "./styles.less";
import { AlertTriangle } from "react-feather";
import { Link } from "react-router-dom";

const MuAlert = ({ message, type = "error", redirectUrl = "#" }) => (
  <div className="mu-notification-container">
    <div className={`mu-notification-content mu-notification-${type}`}>
      <AlertTriangle size={20} />
      <span className="mu-notification-message">{message}</span>
      <Link target="_blank" to={redirectUrl} className="mu-notification-link">
        Ver mais
      </Link>
    </div>
  </div>
);

export const Notification = {
  MuAlert,
};
