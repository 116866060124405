import React from "react";
//Custom Components
import SuretyTabs from "./SuretyTabs";
import LifeTabs from "./LifeTabs";
import CargoOpenPolicyTabs from "./CargoOpenPolicyTabs";
import CargoSingleBoardingTabs from "./CargoSingleBoardingTabs";
//Helper Functions
import { getAllowedTabs } from "./config";
//Config
const productTabs = {
  CARGO_SINGLE_BOARDING: CargoSingleBoardingTabs,
  CARGO_OPEN_POLICY: CargoOpenPolicyTabs,
  SURETY: SuretyTabs,
  LIFE: LifeTabs,
};

const OrderTabs = (props) => {
  const { fetched, type, parentRef, ...remainingProps } = props;

  if (!fetched) return null;

  const ProductView = productTabs[type];

  const allowedTabs = getAllowedTabs(type, remainingProps.jsonTerm);

  return (
    <ProductView
      ref={parentRef}
      allowedTabs={allowedTabs}
      {...remainingProps}
    />
  );
};

export default OrderTabs;
