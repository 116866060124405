const config = {
  CARGO_SINGLE_BOARDING: {},
  CARGO_OPEN_POLICY: {},
  SURETY: {
    principal: null,
    obligee: null,
    term: null,
    detail: null,
  },
  LIFE: {},
};

export function getAllowedTabs(orderType, type) {
  const allowed = {};
  const tabKeys = config[orderType];
  for (let key in tabKeys) {
    if (tabKeys.hasOwnProperty(key)) {
      if (tabKeys[key]) {
        const tabValue = tabKeys[key];
        allowed[key] = !!tabValue.includes(type);
      } else {
        allowed[key] = true;
      }
    }
  }
  return allowed;
}
