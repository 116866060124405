import React from "react";
//Router Lib
import { Redirect, Route } from "react-router-dom";
import { VoipComponent } from "../../components";
const RestrictedRoute = ({
  component: Component,
  authUser,
  currentOrderData,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return authUser ? (
          <>
            <Component
              authUser={authUser}
              currentOrderData={currentOrderData}
              {...props}
            />
            <VoipComponent />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};
export default RestrictedRoute;
