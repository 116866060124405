//Helper functions`
import { setInitialTranslation } from "helpers";

import i18n from "../../translation/i18n";

let translation;
if (!translation) translation = setInitialTranslation("selections");

// Custom Helpers
function getTranslation(title, defaultConfig) {
  return translation.setLabel(...arguments);
}

export const allInsurers = [
  {
    value: "AIG",
    label: i18n.t("insurer.AIG"),
  },
  {
    value: "ANALYSIS",
    label: i18n.t("insurer.ANALYSIS"),
  },
  {
    value: "ARGO_SEGUROS",
    label: i18n.t("insurer.ARGO_SEGUROS"),
  },
  {
    value: "AKAD_SEGUROS",
    label: i18n.t("insurer.AKAD_SEGUROS"),
  },
  {
    value: "AXA_SEGUROS",
    label: i18n.t("insurer.AXA_SEGUROS"),
  },
  {
    value: "BAIL",
    label: i18n.t("insurer.BAIL"),
  },
  {
    value: "BERKLEY_SEGUROS",
    label: i18n.t("insurer.BERKLEY_SEGUROS"),
  },
  {
    value: "BMG_SEGUROS",
    label: i18n.t("insurer.BMG_SEGUROS"),
  },
  {
    value: "CHUBB_SEGUROS",
    label: i18n.t("insurer.CHUBB_SEGUROS"),
  },
  {
    value: "ESSOR_SEGUROS",
    label: i18n.t("insurer.ESSOR_SEGUROS"),
  },
  {
    value: "EXCELSIOR",
    label: i18n.t("insurer.EXCELSIOR"),
  },
  {
    value: "EZZE_SEGUROS",
    label: i18n.t("insurer.EZZE_SEGUROS"),
  },
  {
    value: "AVLA_SEGUROS",
    label: i18n.t("insurer.AVLA_SEGUROS"),
  },
  {
    value: "FIANZA",
    label: i18n.t("insurer.FIANZA"),
  },
  {
    value: "HDI_SEGUROS",
    label: i18n.t("insurer.HDI_SEGUROS"),
  },
  {
    value: "INFINITE_BANK",
    label: i18n.t("insurer.INFINITE_BANK"),
  },
  {
    value: "INVEST_SEGURADORA",
    label: i18n.t("insurer.INVEST_SEGURADORA"),
  },
  {
    value: "JNS_SEGUROS",
    label: i18n.t("insurer.JNS_SEGUROS"),
  },
  {
    value: "JUNTOS_SEGUROS",
    label: i18n.t("insurer.JUNTOS_SEGUROS"),
  },
  {
    value: "LIBERTY_SEGUROS",
    label: i18n.t("insurer.LIBERTY_SEGUROS"),
  },
  {
    value: "METLIFE_SEGUROS",
    label: i18n.t("insurer.METLIFE_SEGUROS"),
  },
  {
    value: "MAXXIMUS",
    label: i18n.t("insurer.MAXXIMUS"),
  },
  {
    value: "PORTO_SEGURO_SEGUROS",
    label: i18n.t("insurer.PORTO_SEGURO_SEGUROS"),
  },
  {
    value: "POTTENCIAL_SEGUROS",
    label: i18n.t("insurer.POTTENCIAL_SEGUROS"),
  },
  {
    value: "PREVISUL_SEGURADORA",
    label: i18n.t("insurer.PREVISUL_SEGURADORA"),
  },
  {
    value: "PRIME_AFIANZADORA",
    label: i18n.t("insurer.PRIME_AFIANZADORA"),
  },
  {
    value: "PRUDENTIAL_SEGUROS",
    label: i18n.t("insurer.PRUDENTIAL_SEGUROS"),
  },
  {
    value: "SOMPO_SEGUROS",
    label: i18n.t("insurer.SOMPO_SEGUROS"),
  },
  {
    value: "SURA_SEGUROS",
    label: i18n.t("insurer.SURA_SEGUROS"),
  },
  {
    value: "TOKIO_MARINE_SEGUROS",
    label: i18n.t("insurer.TOKIO_MARINE_SEGUROS"),
  },
  {
    value: "TRAVELERS_SEGUROS",
    label: i18n.t("insurer.TRAVELERS_SEGUROS"),
  },
  {
    value: "ZURICH_SEGUROS",
    label: i18n.t("insurer.ZURICH_SEGUROS"),
  },
  {
    value: "CAPEMISA_SEGUROS",
    label: i18n.t("insurer.CAPEMISA_SEGUROS"),
  },
  {
    value: "PASI",
    label: i18n.t("insurer.PASI"),
  },
  {
    value: "SWISSRE",
    label: i18n.t("insurer.SWISSRE"),
  },
  {
    value: "AUSTRAL",
    label: i18n.t("insurer.AUSTRAL"),
  },
  {
    value: "FAIRFAX",
    label: i18n.t("insurer.FAIRFAX"),
  },
  {
    value: "COLUMBIA",
    label: i18n.t("insurer.COLUMBIA"),
  },
  {
    value: "NEWE_SEGUROS",
    label: i18n.t("insurer.NEWE_SEGUROS"),
  },
  {
    value: "MITSUI_SEGUROS",
    label: i18n.t("insurer.MITSUI_SEGUROS"),
  },
  {
    value: "HDI_GLOBAL_SEGUROS",
    label: i18n.t("insurer.HDI_GLOBAL_SEGUROS"),
  },
  {
    value: "LIDER_AFIANCADORA",
    label: i18n.t("insurer.LIDER_AFIANCADORA"),
  },
  {
    value: "SUHAI_SEGURADORA",
    label: i18n.t("insurer.SUHAI_SEGURADORA"),
  },
  {
    value: "FORTRESS_GARANTIDORA",
    label: i18n.t("insurer.FORTRESS_GARANTIDORA"),
  },
  {
    value: "UNIMED_SEGURADORA",
    label: i18n.t("insurer.UNIMED_SEGURADORA"),
  },
  {
    value: "ACTUAL_RISK_SEGURADORA",
    label: i18n.t("insurer.ACTUAL_RISK_SEGURADORA"),
  },
  {
    value: "FOR_YOU_BANK",
    label: i18n.t("insurer.FOR_YOU_BANK"),
  },
  {
    value: "MAPFRE_SEGUROS",
    label: i18n.t("insurer.MAPFRE_SEGUROS"),
  },
  {
    value: "SOMBRERO_SEGUROS",
    label: i18n.t("insurer.SOMBRERO_SEGUROS"),
  },
  {
    value: "BRADESCO_SEGUROS",
    label: i18n.t("insurer.BRADESCO_SEGUROS"),
  },
  {
    value: "FC_GARANTIA",
    label: i18n.t("insurer.FC_GARANTIA"),
  },
  {
    value: "BETTER_CAPITAL",
    label: i18n.t("insurer.BETTER_CAPITAL"),
  },
  {
    value: "DANK_BANK",
    label: i18n.t("insurer.DANK_BANK"),
  },
  {
    value: "FATOR_SEGURADORA",
    label: i18n.t("insurer.FATOR_SEGURADORA"),
  },
  {
    value: "SEGURADORA_88I",
    label: i18n.t("insurer.SEGURADORA_88I"),
  },
  {
    value: "ICATU",
    label: i18n.t("insurer.ICATU"),
  },
];

export default {
  allCountries: [
    { value: "0", label: "Escolha um" },
    { value: "AC", label: "Acre" },
    { value: "AL", label: "Alagoas" },
    { value: "AP", label: "Amapá" },
    { value: "AM", label: "Amazonas" },
    { value: "BA", label: "Bahia" },
    { value: "CE", label: "Ceará" },
    { value: "DF", label: "Distrito Federal" },
    { value: "ES", label: "Espírito Santo" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "PA", label: "Pará" },
    { value: "PB", label: "Paraíba" },
    { value: "PR", label: "Paraná" },
    { value: "PE", label: "Pernambuco" },
    { value: "PI", label: "Piauí" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RN", label: "Rio Grande do Norte" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RO", label: "Rondônia" },
    { value: "RR", label: "Roraima" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "SE", label: "Sergipe" },
    { value: "TO", label: "Tocantins" },
  ],
  orderProducts: [
    {
      id: 1,
      value: "CARGO_SINGLE_BOARDING",
      label: i18n.t("orderProducts.cargoSingleBoarding"),
      color: "teal",
    },
    {
      id: 2,
      value: "CARGO_OPEN_POLICY",
      label: i18n.t("orderProducts.cargoOpenPolicy"),
      color: "indigo",
    },
    {
      id: 3,
      value: "SURETY",
      label: i18n.t("orderProducts.surety"),
      color: "green",
    },
    {
      id: 4,
      value: "LIFE",
      label: i18n.t("orderProducts.life"),
      color: "info",
    },
    {
      id: 5,
      value: "ENGINEERING_ALL_RISKS",
      label: i18n.t("orderProducts.engineeringAllRisks"),
      color: "info",
    },
    {
      id: 6,
      value: "EQUIPMENT",
      label: i18n.t("orderProducts.equipment"),
      color: "info",
    },
    {
      id: 7,
      value: "ENVIRONMENTAL_CIVIL_LIABITY",
      label: i18n.t("orderProducts.environmentalCivilLiability"),
      color: "info",
    },
    {
      id: 8,
      value: "PROFESSIONAL_CIVIL_LIABITY",
      label: i18n.t("orderProducts.professionalCivilLiability"),
      color: "info",
    },
    {
      id: 9,
      value: "PORT_OPERATOR_LIABILITY",
      label: i18n.t("orderProducts.portOperatorLiability"),
      color: "info",
    },
    {
      id: 10,
      value: "CORPORATE_HEALTH",
      label: i18n.t("orderProducts.corporateHealth"),
      color: "info",
    },
    {
      id: 11,
      value: "CONDO",
      label: i18n.t("orderProducts.condo"),
      color: "info",
    },
    {
      id: 12,
      value: "CYBER_RISK",
      label: i18n.t("orderProducts.cyberRisk"),
      color: "info",
    },
    {
      id: 13,
      value: "CORPORATE",
      label: i18n.t("orderProducts.corporate"),
      color: "info",
    },
    {
      id: 14,
      value: "AERONAUTICAL",
      label: i18n.t("orderProducts.aeronautical"),
      color: "info",
    },
    {
      id: 15,
      value: "CREDIT",
      label: i18n.t("orderProducts.credit"),
      color: "info",
    },
    {
      id: 16,
      value: "MARITIME_CHARTERER_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.maritimeCharterer"),
      color: "info",
    },
    {
      id: 17,
      value: "EXECUTIVE_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.executive"),
      color: "info",
    },
    {
      id: 18,
      value: "CONSTRUCTION_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.construction"),
      color: "info",
    },
    {
      id: 19,
      value: "LAWYER_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.lawyer"),
      color: "info",
    },
    {
      id: 20,
      value: "ARCHITECT_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.architect"),
      color: "info",
    },
    {
      id: 21,
      value: "DENTIST_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.dentist"),
      color: "info",
    },
    {
      id: 22,
      value: "ACCOUNTANT_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.accountant"),
      color: "info",
    },
    {
      id: 23,
      value: "TECH_COMPANY_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.techCompany"),
      color: "info",
    },
    {
      id: 24,
      value: "ENGINEER_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.engineer"),
      color: "info",
    },
    {
      id: 25,
      value: "DOCTOR_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.doctor"),
      color: "info",
    },
    {
      id: 26,
      value: "ANOTHER_ACTIVITIES_CIVIL_LIABILITY",
      label: i18n.t("orderProducts.anotherActivitiesLiability"),
      color: "info",
    },
    {
      id: 28,
      value: "RENTAL_BOND",
      label: i18n.t("orderProducts.rentalBond"),
      color: "info",
    },
    {
      id: 29,
      value: "FINANCIAL_INSTITUTION",
      label: i18n.t("orderProducts.financialInstitution"),
      color: "info",
    },
    {
      id: 30,
      value: "LGPD",
      label: i18n.t("orderProducts.LGPD"),
      color: "info",
    },
    {
      id: 32,
      value: "RETA_DRONE",
      label: i18n.t("orderProducts.drone"),
      color: "info",
    },
    {
      id: 33,
      value: "RETA_AIR_TRANSPORT",
      label: i18n.t("orderProducts.airTransport"),
      color: "info",
    },
    {
      id: 34,
      value: "NAMED_RISK",
      label: i18n.t("orderProducts.namedRisk"),
      color: "info",
    },
    {
      id: 35,
      value: "IPO",
      label: i18n.t("orderProducts.ipo"),
      color: "info",
    },
    {
      id: 36,
      value: "BUS",
      label: i18n.t("orderProducts.bus"),
      color: "info",
    },
    {
      id: 37,
      value: "EVENT_LIABILITY",
      label: i18n.t("orderProducts.eventLiability"),
      color: "info",
    },
    {
      id: 38,
      value: "GENERAL_LIABILITY",
      label: i18n.t("orderProducts.generalLiability"),
      color: "info",
    },
    {
      id: 39,
      value: "SERVICES_PRIVISION_LIABILITY",
      label: i18n.t("orderProducts.servicesProvisionLiability"),
      color: "info",
    },
    {
      id: 40,
      value: "OPERATIONS_LIABILITY",
      label: i18n.t("orderProducts.operationsLiability"),
      color: "info",
    },
    {
      id: 41,
      value: "RETA",
      label: i18n.t("orderProducts.retaProduct"),
      color: "info",
    },
    {
      id: 42,
      value: "AUTO_INSURANCE",
      label: i18n.t("orderProducts.autoInsuranceProduct"),
      color: "info",
    },
  ],
  probabilities: [
    {
      value: "REMOTE",
      label: getTranslation("probabilities.remote"),
    },
    {
      value: "POSSIBLE",
      label: getTranslation("probabilities.possible"),
    },
    {
      value: "PROBABLE",
      label: getTranslation("probabilities.probable"),
    },
  ],
  bonds: [
    {
      value: "NEW_PROCESS_BOND",
      label: getTranslation("bonds.newProcess"),
    },
    {
      value: "SURETY_REPLACEMENT",
      label: getTranslation("bonds.suretyReplacement"),
    },
    {
      value: "ADDITIONAL_SURETY",
      label: getTranslation("bonds.additionalSurety"),
    },
  ],
  bondTypes: [
    {
      value: "CONSTRUCTION_BOND",
      label: getTranslation("bond.constructionBond"),
    },
    {
      value: "BUSINESS_SERVICES_BOND",
      label: getTranslation("bond.businessServiceBond"),
    },
    {
      value: "SUPPLY_BOND",
      label: getTranslation("bond.supplyBond"),
    },
    {
      value: "ADVANCED_PAYMENT_BOND",
      label: getTranslation("bond.advancedPaymentBond"),
    },
    {
      value: "BIDDING_BOND",
      label: getTranslation("bond.biddingBond"),
    },
    {
      value: "TAX_CREDITS_BOND",
      label: getTranslation("bond.taxCreditsBond"),
    },
    {
      value: "DEALER_PERFORMER_BOND",
      label: getTranslation("bond.dealerPerformerBond"),
    },
    {
      value: "WORK_COMPLETION_BOND",
      label: getTranslation("bond.workCompletationBond"),
    },
    {
      value: "CUSTOMS_BOND",
      label: getTranslation("bond.customsBond"),
    },
    {
      value: "CORRECTIVE_MAINTENANCE_BOND",
      label: getTranslation("bond.correctiveMaintenanceBond"),
    },
    {
      value: "PAYMENT_RETENTION_BOND",
      label: getTranslation("bond.paymentRetentionBond"),
    },
    {
      value: "REAL_ESTATE_BOND",
      label: getTranslation("bond.realStateBond"),
    },
    {
      value: "ENERGY_SECTOR_BOND",
      label: getTranslation("bond.energySectorBond"),
    },
    {
      value: "NAVY_SECTOR_BOND",
      label: getTranslation("bond.navySectorBond"),
    },
    {
      value: "RENT_GUARANTEE",
      label: getTranslation("bond.rentGuaranteeBond"),
    },
    {
      value: "OIL_GAS_SECTOR_BOND",
      label: getTranslation("bond.oilGasSectorBond"),
    },
    {
      value: "LABOUR_APPEAL_FILLING_BOND",
      label: getTranslation("bond.labourAppealFillingBond"),
    },
    {
      value: "CIVIL_COURT_BOND",
      label: getTranslation("bond.civilCourtBond"),
    },
    {
      value: "TAX_ENFORCEMENT_BOND",
      label: getTranslation("bond.taxEnforcementBond"),
    },
    {
      value: "COURT_OTHERS_BOND",
      label: getTranslation("bond.courtOthersBond"),
    },
    {
      value: "LABOUR_SURETY",
      label: getTranslation("bond.labourSurety"),
    },
    {
      value: "FINANCIAL_BOND",
      label: getTranslation("bond.financialBond"),
    },
  ],
  appealTypes: [
    {
      value: "EMBARGOES",
      label: getTranslation("appealType.embargoesOptionLabel"),
    },
    {
      value: "ORDINARY_APPEAL",
      label: getTranslation("appealType.ordinaryAppealOptionLabel"),
    },
    {
      value: "EXTRAORDINARY_APPEAL",
      label: getTranslation("appealType.extraOrdinaryAppealOptionLabel"),
    },
    {
      value: "APPEAL",
      label: getTranslation("appealType.appealOptionLabel"),
    },
    {
      value: "INTERLOCUTORY_APPEAL_ORDINARY",
      label: getTranslation(
        "appealType.interlocutoryAppealOrdinaryOptionLabel"
      ),
    },
    {
      value: "INTERLOCUTORY_APPEAL_REVIEW",
      label: getTranslation("appealType.interlocutoryAppealReviewOptionLabel"),
    },
    {
      value: "RESCISORY_APPEAL",
      label: getTranslation("appealType.recisoryAppealOptionLabel"),
    },
  ],
  actionTypes: [
    {
      value: "FILLED",
      label: getTranslation("actionType.filled"),
    },
    {
      value: "NOT_FILLED",
      label: getTranslation("actionType.notFilled"),
    },
  ],
  crossSellSelection: [
    {
      value: false,
      label: getTranslation("crossSellSelection.no"),
    },
    {
      value: true,
      label: getTranslation("crossSellSelection.yes"),
    },
  ],
  goodsGroups: [
    {
      value: "PRODUCTS",
      label: getTranslation("goodsGroups.products"),
    },
    {
      value: "FOODS",
      label: getTranslation("goodsGroups.foods"),
    },
    {
      value: "BEVERAGES",
      label: getTranslation("goodsGroups.beverages"),
    },
    {
      value: "MEETS",
      label: getTranslation("goodsGroups.meets"),
    },
    {
      value: "GRAINS_AND_SEEDS",
      label: getTranslation("goodsGroups.grains_and_seeds"),
    },
    {
      value: "MACHINES",
      label: getTranslation("goodsGroups.machines"),
    },
    {
      value: "TEXTILE_MATERIALS",
      label: getTranslation("goodsGroups.textile_materials"),
    },
    {
      value: "METALS",
      label: getTranslation("goodsGroups.metals"),
    },
    {
      value: "CHEMICAL_PRODUCTS",
      label: getTranslation("goodsGroups.chemical_products"),
    },
    {
      value: "ALIVE_ANIMALS",
      label: getTranslation("goodsGroups.alive_animals"),
    },
    {
      value: "WEAPONS_AND_MUNITIONS",
      label: getTranslation("goodsGroups.weapons_and_munitions"),
    },
    {
      value: "LEATHER_GOODS",
      label: getTranslation("goodsGroups.leather_goods"),
    },
    {
      value: "GRAPHICS_GOODS",
      label: getTranslation("goodsGroups.graphics_goods"),
    },
    {
      value: "CERAMIC",
      label: getTranslation("goodsGroups.ceramic"),
    },
    {
      value: "INSTRUMENTS",
      label: getTranslation("goodsGroups.instruments"),
    },
    {
      value: "WOOD",
      label: getTranslation("goodsGroups.wood"),
    },
    {
      value: "RUBBER_MATERIALS",
      label: getTranslation("goodsGroups.rubber_materials"),
    },
    {
      value: "RADIOACTIVE_MATERIALS",
      label: getTranslation("goodsGroups.radioactive_materials"),
    },
    {
      value: "MEDICINES",
      label: getTranslation("goodsGroups.medicines"),
    },
    {
      value: "MINERALS",
      label: getTranslation("goodsGroups.minerals"),
    },
    {
      value: "FURNITURE",
      label: getTranslation("goodsGroups.furniture"),
    },
    {
      value: "ART_OBJECTS",
      label: getTranslation("goodsGroups.art_objects"),
    },
    {
      value: "PAPER",
      label: getTranslation("goodsGroups.paper"),
    },
    {
      value: "PLANS",
      label: getTranslation("goodsGroups.plans"),
    },
    {
      value: "PLASTICS",
      label: getTranslation("goodsGroups.plastics"),
    },
    {
      value: "VEHICLES",
      label: getTranslation("goodsGroups.vehicles"),
    },
    {
      value: "CLOTHING",
      label: getTranslation("goodsGroups.clothing"),
    },
    {
      value: "GLASS",
      label: getTranslation("goodsGroups.glass"),
    },
    {
      value: "OTHERS",
      label: getTranslation("goodsGroups.others"),
    },
  ],
  fleetGroups: [
    {
      value: "OWN_FLEET",
      label: getTranslation("fleetGroups.own_fleet"),
    },
    {
      value: "INDEPENDENT_FLEET",
      label: getTranslation("fleetGroups.independent_fleet"),
    },
    {
      value: "THIRDY_PARTY_FLEET",
      label: getTranslation("fleetGroups.thirdy_party_fleet"),
    },
  ],
  principalRoutesGroups: [
    {
      value: "PRODUCTS",
      label: getTranslation("principalRoutesGroups.products"),
    },
    {
      value: "FOODS",
      label: getTranslation("principalRoutesGroups.foods"),
    },
    {
      value: "BEVERAGES",
      label: getTranslation("principalRoutesGroups.beverages"),
    },
    {
      value: "MEETS",
      label: getTranslation("principalRoutesGroups.meets"),
    },
    {
      value: "GRAINS_AND_SEEDS",
      label: getTranslation("principalRoutesGroups.grains_and_seeds"),
    },
    {
      value: "MACHINES",
      label: getTranslation("principalRoutesGroups.machines"),
    },
    {
      value: "TEXTILE_MATERIALS",
      label: getTranslation("principalRoutesGroups.textile_materials"),
    },
    {
      value: "METALS",
      label: getTranslation("principalRoutesGroups.metals"),
    },
    {
      value: "CHEMICAL_PRODUCTS",
      label: getTranslation("principalRoutesGroups.chemical_products"),
    },
    {
      value: "ALIVE_ANIMALS",
      label: getTranslation("principalRoutesGroups.alive_animals"),
    },
    {
      value: "WEAPONS_AND_MUNITIONS",
      label: getTranslation("principalRoutesGroups.weapons_and_munitions"),
    },
    {
      value: "LEATHER_GOODS",
      label: getTranslation("principalRoutesGroups.leather_goods"),
    },
    {
      value: "GRAPHICS_GOODS",
      label: getTranslation("principalRoutesGroups.graphics_goods"),
    },
    {
      value: "CERAMIC",
      label: getTranslation("principalRoutesGroups.ceramic"),
    },
    {
      value: "INSTRUMENTS",
      label: getTranslation("principalRoutesGroups.instruments"),
    },
    {
      value: "WOOD",
      label: getTranslation("principalRoutesGroups.wood"),
    },
    {
      value: "RUBBER_MATERIALS",
      label: getTranslation("principalRoutesGroups.rubber_materials"),
    },
    {
      value: "RADIOACTIVE_MATERIALS",
      label: getTranslation("principalRoutesGroups.radioactive_materials"),
    },
    {
      value: "MEDICINES",
      label: getTranslation("principalRoutesGroups.medicines"),
    },
    {
      value: "MINERALS",
      label: getTranslation("principalRoutesGroups.minerals"),
    },
    {
      value: "FURNITURE",
      label: getTranslation("principalRoutesGroups.furniture"),
    },
    {
      value: "ART_OBJECTS",
      label: getTranslation("principalRoutesGroups.art_objects"),
    },
    {
      value: "PAPER",
      label: getTranslation("principalRoutesGroups.paper"),
    },
    {
      value: "PLANS",
      label: getTranslation("principalRoutesGroups.plans"),
    },
    {
      value: "PLASTICS",
      label: getTranslation("principalRoutesGroups.plastics"),
    },
    {
      value: "VEHICLES",
      label: getTranslation("principalRoutesGroups.vehicles"),
    },
    {
      value: "CLOTHING",
      label: getTranslation("principalRoutesGroups.clothing"),
    },
    {
      value: "GLASS",
      label: getTranslation("principalRoutesGroups.glass"),
    },
    {
      value: "OTHERS",
      label: getTranslation("principalRoutesGroups.others"),
    },
  ],
  openPolicyCoverages: [
    {
      value: "RCTF_C",
      label: getTranslation("openPolicyCoverages.rctf_c"),
    },
    {
      value: "RCTA_C",
      label: getTranslation("openPolicyCoverages.rcta_c"),
    },
    {
      value: "RCAC_C",
      label: getTranslation("openPolicyCoverages.rcac_c"),
    },
    {
      value: "RCTR_VI",
      label: getTranslation("openPolicyCoverages.rctr_vi"),
    },
    {
      value: "RCTR_C",
      label: getTranslation("openPolicyCoverages.rctr_c"),
    },
    {
      value: "THEFT",
      label: getTranslation("openPolicyCoverages.theft"),
    },
    {
      value: "DAMAGE",
      label: getTranslation("openPolicyCoverages.damage"),
    },
    {
      value: "TRACK_CLEANING",
      label: getTranslation("openPolicyCoverages.track_cleaning"),
    },
    {
      value: "CONTAINER_COVERAGE",
      label: getTranslation("openPolicyCoverages.container_coverage"),
    },
    {
      value: "LOADING_UNLOADING_LIFTING",
      label: getTranslation("openPolicyCoverages.loading_unloading_lifting"),
    },
    {
      value: "CARRIER_DEPOT_THEFT",
      label: getTranslation("openPolicyCoverages.carrier_depot_theft"),
    },
    {
      value: "QUALIFIED_WAREHOUSE_THEFT",
      label: getTranslation("openPolicyCoverages.qualified_warehouse_theft"),
    },
    {
      value: "DOWNTIME_REFRIGRETING_MACHINES",
      label: getTranslation(
        "openPolicyCoverages.downtime_refrigreting_machines"
      ),
    },
    {
      value: "LIVE_ANIMALS",
      label: getTranslation("openPolicyCoverages.live_animals"),
    },
    {
      value: "ART_OBJECTS",
      label: getTranslation("openPolicyCoverages.art_objects"),
    },
    {
      value: "COMMERCIAL_RESIDENTIAL_REMOVALS",
      label: getTranslation(
        "openPolicyCoverages.commercial_residential_removals"
      ),
    },
    {
      value: "VEHICLES_TRAVELING_OWN_MEANS",
      label: getTranslation("openPolicyCoverages.vehicles_traveling_own_means"),
    },
    {
      value: "SPECIAL_TRANSPORT_BEVERAGE_COVER",
      label: getTranslation(
        "openPolicyCoverages.special_transport_beverage_cover"
      ),
    },
  ],
  openPolicyLogisticTypes: [
    {
      value: "DISTRIBUTION",
      label: getTranslation("openPolicyLogisticTypes.distribution"),
    },
    {
      value: "POINT_TO_POINT",
      label: getTranslation("openPolicyLogisticTypes.point_to_point"),
    },
  ],
  openPolicyTracking: [
    {
      value: "FUEL_BLOCKER",
      label: getTranslation("openPolicyTracking.fuel_blocker"),
    },
    {
      value: "PANIC_BUTTON",
      label: getTranslation("openPolicyTracking.panic_button"),
    },
    {
      value: "RELEASE_SENSOR",
      label: getTranslation("openPolicyTracking.release_sensor"),
    },
    {
      value: "CARGO_BED_LOCKER",
      label: getTranslation("openPolicyTracking.cargo_bed_locker"),
    },
    {
      value: "OTHER",
      label: getTranslation("openPolicyTracking.other"),
    },
    {
      value: "NONE",
      label: getTranslation("openPolicyTracking.none"),
    },
  ],
  openPolicyRiskMngm: [
    {
      value: "PROFESSIONAL_ESCORT",
      label: getTranslation("openPolicyRiskMngm.professional_escort"),
    },
    {
      value: "VEHICLE_TRACKING",
      label: getTranslation("openPolicyRiskMngm.vehicle_tracking"),
    },
    {
      value: "NONE",
      label: getTranslation("openPolicyRiskMngm.none"),
    },
  ],
  openPolicyDocuments: [
    {
      value: "CTE",
      label: getTranslation("openPolicyDocuments.cte"),
    },
    {
      value: "CARGO_MANIFEST",
      label: getTranslation("openPolicyDocuments.cargo_manifest"),
    },
    {
      value: "CARGO_LOADING",
      label: getTranslation("openPolicyDocuments.cargo_loading"),
    },
    {
      value: "COLLECTION_ORDER",
      label: getTranslation("openPolicyDocuments.collection_order"),
    },
    {
      value: "BILL_OF_LANDING",
      label: getTranslation("openPolicyDocuments.bill_of_landing"),
    },
    {
      value: "TIF",
      label: getTranslation("openPolicyDocuments.tif"),
    },
    {
      value: "MINUTE",
      label: getTranslation("openPolicyDocuments.minute"),
    },
    {
      value: "INVOICE",
      label: getTranslation("openPolicyDocuments.invoice"),
    },
    {
      value: "AWB",
      label: getTranslation("openPolicyDocuments.awb"),
    },
    {
      value: "OTHER",
      label: getTranslation("openPolicyDocuments.other"),
    },
  ],
  taxesInsurer: {
    BERKLEY_SEGUROS: {
      label: getTranslation("taxesInsurer.berkley_seguros"),
    },
    JNS_SEGUROS: {
      label: getTranslation("taxesInsurer.jns_seguros"),
    },
    JUNTOS_SEGUROS: {
      label: getTranslation("taxesInsurer.juntos_seguros"),
    },
    SOMPO_SEGUROS: {
      label: getTranslation("taxesInsurer.sompo_seguros"),
    },
    TOKIO_MARINE_SEGUROS: {
      label: getTranslation("taxesInsurer.TOKIO_MARINE_SEGUROS"),
    },
    BMG_SEGUROS: {
      label: getTranslation("taxesInsurer.bmg_seguros"),
    },
    PORTO_SEGURO_SEGUROS: {
      label: getTranslation("insurer.PORTO_SEGURO_SEGUROS"),
    },
    POTTENCIAL_SEGUROS: {
      label: getTranslation("insurer.POTTENCIAL_SEGUROS"),
    },
    AVLA_SEGUROS: {
      label: getTranslation("insurer.AVLA_SEGUROS"),
    },
    EZZE_SEGUROS: {
      label: getTranslation("insurer.EZZE_SEGUROS"),
    },
    MITSUI: {
      label: getTranslation("insurer.MITSUI"),
    },
    HDI_GLOBAL_SEGUROS: {
      label: getTranslation("insurer.HDI_GLOBAL_SEGUROS"),
    },
    SURA_SEGUROS: {
      label: getTranslation("insurer.SURA_SEGUROS"),
    },
    COLUMBIA: {
      label: getTranslation("insurer.COLUMBIA"),
    },
    LIDER_AFIANCADORA: {
      label: getTranslation("insurer.LIDER_AFIANCADORA"),
    },
    ZURICH_SEGUROS: {
      label: getTranslation("insurer.ZURICH_SEGUROS"),
    },
    INVEST_SEGURADORA: {
      label: getTranslation("insurer.INVEST_SEGURADORA"),
    },
    FIANZA: {
      label: getTranslation("insurer.FIANZA"),
    },
    INFINITE_BANK: {
      label: getTranslation("insurer.INFINITE_BANK"),
    },
    ESSOR_SEGUROS: {
      label: getTranslation("insurer.ESSOR_SEGUROS"),
    },
    EXCELSIOR: {
      label: getTranslation("insurer.EXCELSIOR"),
    },
    SUHAI_SEGURADORA: {
      label: getTranslation("insurer.SUHAI_SEGURADORA"),
    },
    ACTUAL_RISK_SEGURADORA: {
      label: getTranslation("insurer.ACTUAL_RISK_SEGURADORA"),
    },
    FOR_YOU_BANK: {
      label: getTranslation("insurer.FOR_YOU_BANK"),
    },
    SOMBRERO_SEGUROS: {
      label: getTranslation("insurer.SOMBRERO_SEGUROS"),
    },
    MAPFRE_SEGUROS: {
      label: getTranslation("insurer.MAPFRE_SEGUROS"),
    },
    FC_GARANTIA: {
      label: getTranslation("insurer.FC_GARANTIA"),
    },
    BETTER_CAPITAL: {
      label: getTranslation("insurer.BETTER_CAPITAL"),
    },
    NEWE_SEGUROS: {
      label: getTranslation("insurer.NEWE_SEGUROS"),
    },
  },
  taxesStatus: {
    RESERVED: {
      color: "#8dd7cf",
      label: getTranslation("taxesStatus.reserved"),
    },
    PENDING_ANALYSIS: {
      color: "#8dd7cf",
      label: getTranslation("taxesStatus.pending_analysis"),
    },
    ERROR: {
      color: "#e9a2ad",
      label: getTranslation("taxesStatus.error"),
    },
    DESIGNATION_LETTER_NEEDED: {
      color: "#f3c19d",
      label: getTranslation("taxesStatus.designation_letter_needed"),
    },
    DOCUMENTATION_NEEDED: {
      color: "#f3c19d",
      label: getTranslation("taxesStatus.documentation_needed"),
    },
    EXPIRED: {
      color: "#fbe192",
      label: getTranslation("taxesStatus.expired"),
    },
    REFUSED: {
      color: "#e9a2ad",
      label: getTranslation("taxesStatus.refused"),
    },
    INSURER_REGISTER_NEEDED: {
      color: "#f3c19d",
      label: getTranslation("taxesStatus.register_needed"),
    },
    INSURER_RATE_LIMIT: {
      color: "#fbe192",
      label: getTranslation("taxesStatus.insurer_rate_limit"),
    },
  },
  meansOfTransportation: [
    {
      value: "ROAD_MODAL",
      label: "Rodoviário",
    },
    {
      value: "RAIL_MODAL",
      label: "Ferroviário",
    },
    {
      value: "AIR_MODAL",
      label: "Aéreo",
    },
    {
      value: "MARINE_MODAL",
      label: "Marítimo",
    },
  ],
  currencies: [
    {
      value: "BRA",
      label: "R$",
      symbol: "R$",
    },
    {
      value: "USD",
      label: "USD$",
      symbol: "$",
    },
    {
      value: "EUR",
      label: "€",
      symbol: "€",
    },
  ],
  customerType: [
    {
      value: "CARRYING",
      label: "Transportador",
    },
    {
      value: "CARGO_OWNER",
      label: "Dono da carga",
    },
  ],
  vehicleType: [
    {
      value: "OWN_VEHICLE",
      label: "Próprio",
    },
    {
      value: "SELF_EMPLOYED_VEHICLE",
      label: "Autônomo",
    },
    {
      value: "SHIPPING_COMPANY",
      label: "Transportadora",
    },
  ],
  incoTerms: [
    {
      value: "EXW",
      label: "EXW",
    },
    {
      value: "FCA",
      label: "FCA",
    },
    {
      value: "FAS",
      label: "FAS",
    },
    {
      value: "FOB",
      label: "FOB",
    },
    {
      value: "CFR",
      label: "CFR",
    },
    {
      value: "CIF",
      label: "CIF",
    },
    {
      value: "CPT",
      label: "CPT",
    },
    {
      value: "CIP",
      label: "CIP",
    },
    {
      value: "DAT",
      label: "DAT",
    },
    {
      value: "DAP",
      label: "DAP",
    },
    {
      value: "DDP",
      label: "DDP",
    },
  ],
  companyActivity: [
    {
      value: "LOADING_AGENT",
      label: "Agente de carga",
    },
    {
      value: "AGRIBUSINESS",
      label: "Agronegócio",
    },
    {
      value: "TRUCK_DRIVER",
      label: "Caminhoneiro",
    },
    {
      value: "COMMERCE",
      label: "Comércio",
    },
    {
      value: "FORWARDING",
      label: "Despachante aduaneiro",
    },
    {
      value: "SHIPPER",
      label: "Embarcadora",
    },
    {
      value: "EXPORTER",
      label: "Exportadora",
    },
    {
      value: "IMPORTER",
      label: "Importadora",
    },
    {
      value: "INDUSTRY",
      label: "Indústria",
    },
    {
      value: "OPERATOR",
      label: "Operador logístico",
    },
    {
      value: "TRADING",
      label: "Trading",
    },
    {
      value: "SHIPPING_COMPANY",
      label: "Transportadora",
    },
    {
      value: "OTHERS",
      label: "Outros",
    },
  ],
  openPolicyFrequency: [
    {
      value: "RARELY",
      label: getTranslation("openPolicyFrequency.rarely"),
    },
    {
      value: "OCCASIONALLY",
      label: getTranslation("openPolicyFrequency.occasionally"),
    },
    {
      value: "OFTEN",
      label: getTranslation("openPolicyFrequency.often"),
    },
    {
      value: "VERY_OFTEN",
      label: getTranslation("openPolicyFrequency.very_often"),
    },
  ],
  transportDocuments: [
    {
      value: "CTE",
      label: getTranslation("transportDocuments.cte"),
    },
    {
      value: "BILL_OF_LADING",
      label: getTranslation("transportDocuments.bill_of_lading"),
    },
    {
      value: "CARGO_MANIFEST",
      label: getTranslation("transportDocuments.cargo_manifest"),
    },
    {
      value: "CARGO_PACKING_LIST",
      label: getTranslation("transportDocuments.cargo_packing_list"),
    },
    {
      value: "COLLECTION_ORDER",
      label: getTranslation("transportDocuments.collection_order"),
    },
    {
      value: "RAILWAY_BILL_OF_LADING",
      label: getTranslation("transportDocuments.railway_bill_of_lading"),
    },
    {
      value: "MINUTES",
      label: getTranslation("transportDocuments.minutes"),
    },
    {
      value: "INVOICE",
      label: getTranslation("transportDocuments.invoice"),
    },
    {
      value: "AWB",
      label: getTranslation("transportDocuments.awb"),
    },
    {
      value: "OTHER",
      label: getTranslation("transportDocuments.other"),
    },
  ],
  trackings: [
    {
      value: "FUEL_BLOCKER",
      label: getTranslation("trackings.fuel_blocker"),
    },
    {
      value: "DOOR_OPENING_SENSORS",
      label: getTranslation("trackings.door_opening_sensors"),
    },
    {
      value: "TRAILER_SENSOR",
      label: getTranslation("trackings.trailer_sensor"),
    },
    {
      value: "PANIC_BUTTON",
      label: getTranslation("trackings.panic_button"),
    },
    {
      value: "TRUNK_LOCK",
      label: getTranslation("trackings.trunk_lock"),
    },
    {
      value: "NONE",
      label: getTranslation("trackings.none"),
    },
    {
      value: "OTHER",
      label: getTranslation("trackings.other"),
    },
  ],
  reasonTakeInsurance: [
    {
      value: "CONVENTION_COLLECTIVE_BARGAINING",
      label: getTranslation(
        "reasonTakeInsurance.convention_collective_bargaining"
      ),
    },
    {
      value: "CONTRACTUAL_REQUIREMENT",
      label: getTranslation("reasonTakeInsurance.contractual_requirement"),
    },
    {
      value: "OFFER_BENEFIT",
      label: getTranslation("reasonTakeInsurance.offer_benefit"),
    },
    {
      value: "OTHERS",
      label: getTranslation("reasonTakeInsurance.others"),
    },
  ],
  coverages: [
    {
      value: "DEATH",
      label: getTranslation("coverages.death"),
    },
    {
      value: "NATURAL_DEATH",
      label: getTranslation("coverages.natural_death"),
    },
    {
      value: "ACCIDENTAL_DEATH",
      label: getTranslation("coverages.accidental_detal"),
    },
    {
      value: "PERMANENT_DISABILITY",
      label: getTranslation("coverages.permanent_disability"),
    },
    {
      value: "MEDICAL_EXPENSES",
      label: getTranslation("coverages.medical_expenses"),
    },
    {
      value: "CRITICAL_ILLNESS",
      label: getTranslation("coverages.critical_illness"),
    },
    {
      value: "CONGENITAL_DISORDER",
      label: getTranslation("coverages.congenital_disorder"),
    },
    {
      value: "HOSPITAL_CASH",
      label: getTranslation("coverages.hospital_cash"),
    },
    {
      value: "PRESUMPTIVE_DISABILITY",
      label: getTranslation("coverages.presumptive_disability"),
    },
    {
      value: "TOTAL_DISABILITY",
      label: getTranslation("coverages.total_disability"),
    },
    {
      value: "EMPLOYMENT_INSURANCE_SICKNESS",
      label: getTranslation("coverages.employment_insurance_sickness"),
    },
  ],
  assistances: [
    {
      value: "INDIVIDUAL_FUNERAL_ALLOWANCE",
      label: getTranslation("assistance.individual_funeral_allowance"),
    },
    {
      value: "FAMILIAR_FUNERAL_ALLOWANCE",
      label: getTranslation("assistance.familiar_funeral_allowance"),
    },
    {
      value: "SPOUSAL_DISCOUNT",
      label: getTranslation("assistance.spousal_discont"),
    },
    {
      value: "CHILDREN_DISCOUNT",
      label: getTranslation("assistance.children_discount"),
    },
    {
      value: "FOOD_STAMPS_ASSISTANCE",
      label: getTranslation("assistance.food_stamps_assistance"),
    },
    {
      value: "SEVERANCE_PAY",
      label: getTranslation("assistance.severance_pay"),
    },
    {
      value: "MATERNITY_ALLOWANCE",
      label: getTranslation("assistance.maternity_allowance"),
    },
  ],
  capitalTypeEntries: [
    {
      value: "UNIFORM_VALUE",
      label: getTranslation("capitalType.uniform_value"),
    },
    {
      value: "SALARY_MULTIPLY",
      label: getTranslation("capitalType.salary_multiply"),
    },
  ],
  whatsAppTemplateOptions: [
    {
      value: "order_update",
      label: "Primeiro contato",
      message: `<span>Oi <span id="param_0" class="param_style" >#1</span>, tudo bem? 👋 <br/>  Aqui é <span id="param_1" class="param_style" >#2</span>, especialista em seguro <span id="param_2" class="param_style" >#3</span> da Mutuus Seguros, e estou aqui para te ajudar. 😊 <br/> Preciso saber só de mais alguns detalhes <span id="param_3" class="param_style" >#4</span>. <br/> Podemos falar agora? 😉</span>`,
      params: [
        { value: "", required: true },
        { value: "", required: true },
        { value: "", required: true },
        { value: "", required: false },
      ],
    },
    {
      value: "follow_up",
      label: "Mensagem genérica",
      message: `<span>Olá, tudo bem? 👋 <br/> Aqui é <span id="param_0" class="param_style" >#1</span> da Mutuus Seguros.<br/> <span id="param_1" class="param_style" >#2</span></span>`,
      params: [
        { value: "", required: true },
        { value: "", required: true },
      ],
    },
    {
      value: "em_contato",
      label: "Continuar pelo Whats",
      message: `<span>Olá, tudo bem? 👋 <br/>  Aqui é <span id="param_1" class="param_style" >#2</span> da Mutuus Seguros. 😊 <br/> Acabamos de nos falar por telefone <br/><br/>Vamos dar continuidade ao seu atendimento por aqui! 😁</span>`,
      params: [{ value: "", required: true }],
    },
    {
      value: "continuar_cotacao_link_corpo",
      label: "Continuar Cotação (Botão Ligar Mutuus)",
      message: `<span>Olá <span id="param_0" class="param_style" >#1</span>, tudo bem? 👋 <br/>  Aqui é <span id="param_1" class="param_style" >#2</span> da Mutuus Seguros! E estou passando por aqui para te ajudar. <br/><br/> Percebemos que você iniciou uma cotação de <span id="param_2" class="param_style" >#3</span> em nosso site, porém não deu andamento. <br/><br/> Caso queira informar mais alguma informação relevante sobre sua empresa, você pode fazer isso a qualquer momento em nosso site, basta clicar aqui 👇  <br/><br/> <span id="param_3" class="param_style" >#4</span> <br/><br/> Vamos prosseguir? 😄</span>`,
      params: [
        { value: "", required: true },
        { value: "", required: true },
        { value: "", required: true },
        { value: "", required: true },
      ],
    },
  ],
  bussinessPartners: {
    EVO: {
      label: i18n.t("bussinessPartners.evo"),
    },
    FRETEBRAS: {
      label: i18n.t("bussinessPartners.fretebras"),
    },
    FRETESPOT: {
      label: i18n.t("bussinessPartners.fretespot"),
    },
    FRETEFY: {
      label: i18n.t("bussinessPartners.fretefy"),
    },
    MADESA: {
      label: i18n.t("bussinessPartners.madesa"),
    },
    TRUCKERDOAGRO: {
      label: i18n.t("bussinessPartners.trucker-do-agro"),
    },
    FRETECOMLUCRO: {
      label: i18n.t("bussinessPartners.frete-com-lucro"),
    },
    CARGOSNAP: {
      label: i18n.t("bussinessPartners.cargo-snap"),
    },
    CHEAPTOSHIP: {
      label: i18n.t("bussinessPartners.cheap-to-ship"),
    },
    OTIMIZY: {
      label: i18n.t("bussinessPartners.otimizy"),
    },
    TRUGGHUB: {
      label: i18n.t("bussinessPartners.trugghub"),
    },
    DOMANI: {
      label: i18n.t("bussinessPartners.domani"),
    },
    ESALES: {
      label: i18n.t("bussinessPartners.esales"),
    },
    CARGOX: {
      label: i18n.t("bussinessPartners.cargox"),
    },
    PROJURIS: {
      label: i18n.t("bussinessPartners.projuris"),
    },
    LINKLEI: {
      label: i18n.t("bussinessPartners.linklei"),
    },
    GERDAU: {
      label: i18n.t("bussinessPartners.gerdau"),
    },
    BADUK: {
      label: i18n.t("bussinessPartners.baduk"),
    },
    VALORFUTURO: {
      label: i18n.t("bussinessPartners.valor-futuro"),
    },
    GRUPOBRUGNARA: {
      label: i18n.t("bussinessPartners.grupo-brugnara"),
    },
    CONLICITACAO: {
      label: i18n.t("bussinessPartners.conlicitacao"),
    },
    CRIATIVANDO: {
      label: i18n.t("bussinessPartners.criativando"),
    },
    FOR_YOU_BANK: {
      label: i18n.t("bussinessPartners.for_you_bank"),
    },
    AUSTRO: {
      label: i18n.t("bussinessPartners.austro"),
    },
    SIENGE: {
      label: i18n.t("bussinessPartners.sienge"),
    },
    FERRAMENTAPROSPECCAO: {
      label: i18n.t("bussinessPartners.ferramenta-prospeccao"),
    },
    LICITACOESPUBLICAS: {
      label: i18n.t("bussinessPartners.LICITACOESPUBLICAS"),
    },
    LEADSTER: {
      label: i18n.t("bussinessPartners.LEADSTER"),
    },
    SEGURODECARGADIGITAL: {
      label: i18n.t("bussinessPartners.SEGURODECARGADIGITAL"),
    },
    MLREATIVACAO: {
      label: i18n.t("bussinessPartners.MLREATIVACAO"),
    },
    MLLICITACAO: {
      label: i18n.t("bussinessPartners.MLLICITACAO"),
    },
    MLLEADSSEMFECHAMENTO: {
      label: i18n.t("bussinessPartners.MLLEADSSEMFECHAMENTO"),
    },
    MLPROSPECCAO: {
      label: i18n.t("bussinessPartners.MLPROSPECCAO"),
    },
  },
  insurerByProduct: {
    AUTO_INSURANCE: allInsurers,
    CARGO_SINGLE_BOARDING: allInsurers,
    CARGO_OPEN_POLICY: allInsurers,
    CARGO_INTERNATIONAL: allInsurers,
    SURETY: allInsurers,
    LIFE: allInsurers,
    ENGINEERING_ALL_RISKS: allInsurers,
    EQUIPMENT: allInsurers,
    ENVIRONMENTAL_CIVIL_LIABITY: allInsurers,
    PROFESSIONAL_CIVIL_LIABITY: allInsurers,
    PORT_OPERATOR_LIABILITY: allInsurers,
    CORPORATE_HEALTH: allInsurers,
    EVENT_LIABILITY: allInsurers,
    OPERATIONS_LIABILITY: allInsurers,
    GENERAL_LIABILITY: allInsurers,
    SERVICES_PRIVISION_LIABILITY: allInsurers,
    CONDO: allInsurers,
    CYBER_RISK: allInsurers,
    CORPORATE: allInsurers,
    RETA: allInsurers,
    AERONAUTICAL: allInsurers,
    CREDIT: allInsurers,
    MARITIME_CHARTERER_CIVIL_LIABILITY: allInsurers,
    EXECUTIVE_CIVIL_LIABILITY: allInsurers,
    CONSTRUCTION_CIVIL_LIABILITY: allInsurers,
    LAWYER_CIVIL_LIABILITY: allInsurers,
    ARCHITECT_CIVIL_LIABILITY: allInsurers,
    DENTIST_CIVIL_LIABILITY: allInsurers,
    ACCOUNTANT_CIVIL_LIABILITY: allInsurers,
    TECH_COMPANY_CIVIL_LIABILITY: allInsurers,
    ENGINEER_CIVIL_LIABILITY: allInsurers,
    DOCTOR_CIVIL_LIABILITY: allInsurers,
    ANOTHER_ACTIVITIES_CIVIL_LIABILITY: allInsurers,
    BUSINESS: allInsurers,
    RENTAL_BOND: allInsurers,
    FINANCIAL_INSTITUTION: allInsurers,
    LGPD: allInsurers,
    RETA_DRONE: allInsurers,
    RETA_AIR_TRANSPORT: allInsurers,
    NAMED_RISK: allInsurers,
    IPO: allInsurers,
    BUS: allInsurers,
  },
  policyTypes: [
    {
      value: "RCTRC",
      label: "RCTR-C",
    },
    {
      value: "RCFDC",
      label: "RCF-DC",
    },
    {
      value: "TN",
      label: "TN",
    },
    {
      value: "RCTRVI",
      label: "RCTR-VI",
    },
    {
      value: "RCV",
      label: "RC-V",
    },
  ],
  registerApplicationTypes: [
    {
      value: "ATEM",
      label: "AT&M",
    },
    {
      value: "AVERBA_WEB",
      label: "Averbaweb",
    },
    {
      value: "SURA",
      label: "Sura",
    },
    {
      value: "PORTO_SEGURO",
      label: "Porto Seguro",
    },
    {
      value: "CITNET",
      label: "Citnet",
    },
    {
      value: "AVERBAMAIS",
      label: "Averba+",
    },
  ],
  coverageTypeTN: [
    {
      value: "AMPLA_A",
      label: "AMPLA A",
    },
    {
      value: "RESTRITA_B",
      label: "RESTRITA B",
    },
    {
      value: "RESTRITA_C",
      label: "RESTRITA C",
    },
  ],
  capitalTypes: [
    {
      value: "UNIFORM_VALUE",
      label: i18n.t("life.capitalTypes.uniformValue"),
    },
    {
      value: "SALARY_MULTIPLY",
      label: i18n.t("life.capitalTypes.salaryMultiply"),
    },
  ],
};
