//Translation
import Translation from "../translation";

export function prepareLoading(
  loading = false,
  preparing = false,
  resetting = false,
  saving = false
) {
  let spinning = false,
    tip = "";
  if (preparing) {
    spinning = true;
    tip = Translation.preparingOrderLabel;
  } else if (saving) {
    spinning = true;
    tip = Translation.savingOrderLabel;
  } else if (loading) {
    spinning = true;
    tip = Translation.loadingOrderLabel;
  } else if (resetting) {
    spinning = true;
    tip = Translation.resettingOrderLabel;
  }
  return { spinning, tip };
}
