import React, { Component, createRef } from "react";
//Translation
import Translation from "./translation";
//Redux
import { connect, Provider } from "react-redux";
import { bindActionCreators } from "redux";
//Selectors
import {
  extractOrder,
  isFetchingOrder,
  fetchedOrder,
  isResettingOrder,
  isPreparingOrder,
  isSavingOrder,
  extractOrderConfigurations,
  getSelectedTab,
  getOrderErrors,
} from "appRedux/selectors";
//Actions
import {
  fetchOrder,
  clearOrderConfigurations,
  resetOrderConfigurations,
  resetOrderStarted,
  saveOrderConfigurations,
  handleOrderChange,
  searchCompany,
  changeTab,
  asyncRemoveError,
  fetchCompanyData,
} from "appRedux/actionCreators";
//Components
import { Row, Col, Spin, Modal, Button } from "antd";
//Custom Components
import {
  OrderPreserveRoute,
  OrderActions,
  OrderTabs,
  OrderResume,
} from "components";
//Custom Css
import "./Styles.less";
//Custom Helpers
import { prepareLoading } from "./helpers";
import { withRef } from "../../../util/forwardRef";
import { allowedOrderTabsToPolicyActions } from "../../../constants";
import store from "../../../store";
import { LoadDealData } from "../../../components/Order/LoadDealData";
//Enhanced Components
const EnhancedOrderTabs = withRef(OrderTabs);

class OrderDetails extends Component {
  state = {
    externalModified: false,
    internalModified: false,
    isSavingOrder: false,
    selectedSuperiorTab: "1",
    documentNumber: null,
  };

  orderRef = createRef();

  componentDidMount() {
    const {
      actions: { fetchOrder },
      match,
    } = this.props;
    this.orderId = match.params.id;
    fetchOrder(this.orderId);
    this.handleChangeTab("initial");
  }

  componentWillUnmount() {
    const {
      actions: { clearOrderConfigurations },
    } = this.props;
    clearOrderConfigurations();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) this.handleBondModalOpen();

    if (this.props.configurations) {
      const {
        match,
        configurations: { initial },
        actions: { fetchCompanyData },
      } = this.props;

      if (initial && initial.documentNumber !== this.state.documentNumber) {
        this.setState({
          documentNumber: initial.documentNumber,
        });

        if (!(initial.documentNumber.length < 14)) {
          fetchCompanyData({
            documentNumber: initial.documentNumber,
            id: match.params.id,
          });
        }
      }
    }
  }

  handleBondModalClose = () => {
    this.setState(
      {
        openBondModal: false,
      },
      () => {
        this.props.history.push("/crm/orders");
      }
    );
  };

  shouldBlockNavigation = (location) => {
    const { externalModified, internalModified } = this.state;
    return internalModified || externalModified;
  };

  navigate = (path) => {
    return this.props.history.push(path);
  };

  resetModified = () => {
    this.setState({
      externalModified: false,
      internalModified: false,
    });
  };

  setInternalModified = (value) => {
    this.setState({
      internalModified: value,
    });
  };

  setExternalModified = (value) => {
    this.setState({
      externalModified: value,
    });
  };

  handleChange = (type, productType) => (changes) => {
    const { internalModified } = this.state;
    const {
      actions: { handleOrderChange, asyncRemoveError },
    } = this.props;
    if (type === "generalV2" && productType === "CARGO_OPEN_POLICY") {
      asyncRemoveError(`${changes.attr}`);
    } else if (type === "term" && productType === "SURETY") {
      asyncRemoveError(`${changes.attr}`);
    } else {
      asyncRemoveError(`${type}.${changes.attr}`);
    }
    if (!internalModified) this.setInternalModified(true);
    const changeType = Array.isArray(changes) ? "multiple" : "single";
    return handleOrderChange({
      changeType: changeType,
      changes: changes,
      type: type,
    });
  };

  handleResetOrder = () => {
    const { resetOrderStarted, asyncRemoveError } = this.props.actions;
    asyncRemoveError("all");
    resetOrderStarted();
    if (this.orderRef && this.orderRef.current)
      this.orderRef.current.resetTabs();
    return this.resetOrderConfigurations();
  };

  resetOrderConfigurations = () => {
    const {
      actions: { resetOrderConfigurations },
    } = this.props;
    const resetModified = this.resetModified;
    setTimeout(() => {
      resetOrderConfigurations(this.orderId);
      resetModified();
    }, 500);
  };

  handleCompanySearch = (type) => () => {
    const {
      actions: { searchCompany },
    } = this.props;
    const { externalModified } = this.state;
    if (!externalModified) this.setExternalModified(true);
    searchCompany(type);
  };

  showConfirm = (key) => {
    const saveOrder = this.handleOrderSave;
    Modal.confirm({
      title: "Deseja salvar as suas alterações?",
      content: "Confirme para salvar os dados alterados",
      okText: "Sim",
      cancelText: "Não",
      onOk() {
        saveOrder();
      },
    });
  };

  handleChangeTab = (key) => {
    const {
      actions: { changeTab },
    } = this.props;
    if (!this.shouldBlockNavigation()) {
      changeTab(key);
    } else {
      this.showConfirm(key);
    }
  };

  changeSuperiorTab = (key) => {
    if (typeof key === "string") {
      if (!this.shouldBlockNavigation()) {
        this.setState({ selectedSuperiorTab: key });
        this.handleChangeTab("initial");
      } else {
        this.showConfirm(key);
      }
    }
  };
  handleOrderSave = async () => {
    const { saveOrderConfigurations } = this.props.actions;
    const { selectedTab } = this.props;

    const getExternalChanges = () => {
      if (this.orderRef) return this.orderRef.current.getChanges();
      return null;
    };

    const externalChanges = await getExternalChanges();

    const onSaveCallback = () => {
      const {
        match,
        configurations: { initial },
        actions: { fetchCompanyData },
      } = this.props;
      if (initial && initial.documentNumber) {
        fetchCompanyData({
          documentNumber: initial.documentNumber,
          id: match.params.id,
        });
      }
      this.resetModified();
    };

    if (externalChanges !== null) {
      saveOrderConfigurations({
        id: this.orderId,
        callback: onSaveCallback,
        externalChanges: externalChanges,
        selectedTab: selectedTab,
      });
    }
  };

  render() {
    /*State*/
    const {
      internalModified,
      externalModified,
      selectedSuperiorTab,
    } = this.state;
    /*Props*/
    const {
      loading,
      resetting,
      preparing,
      saving,
      fetchedOrder,
      data,
      configurations,
      OrderErrors,
      selectedTab,
      actions: { asyncRemoveError },
    } = this.props;
    /*Stage Indicators*/
    const { spinning, tip } = prepareLoading(
      loading,
      preparing,
      resetting,
      saving
    );
    /*Constants*/
    const orderType = data && data.type;
    const jsonTerm = data && data.bond && data.bond.jsonTerm;
    const modified = externalModified || internalModified;

    /*Custom Components*/
    const extraContent = (
      <Provider store={store}>
        <OrderActions
          fetched={fetchedOrder}
          modified={modified}
          onReset={this.handleResetOrder}
          onSave={this.handleOrderSave}
          superiorTabValue={selectedSuperiorTab}
          type={orderType}
        />
        <LoadDealData
          superiorTabValue={selectedSuperiorTab}
          documentNumber={this.state.documentNumber}
          orderId={this.orderId}
        />
      </Provider>
    );

    return (
      <section>
        <OrderPreserveRoute
          when={this.internalModified}
          navigate={this.navigate}
          shouldBlockNavigation={this.shouldBlockNavigation}
        />
        <Spin
          spinning={spinning}
          tip={tip}
          className="orderDetails-spinWrapper"
        >
          <div className="gx-module-detail gx-module-list">
            <div className="gx-module-detail-item">
              <OrderResume
                data={data}
                id={this.orderId}
                orderLabel={Translation.orderLabel}
                orderType={orderType}
              />
              <EnhancedOrderTabs
                fetched={fetchedOrder}
                type={orderType}
                id={this.orderId}
                data={data}
                ref={this.orderRef}
                modified={modified}
                setModified={this.setExternalModified}
                jsonTerm={jsonTerm}
                configurations={configurations}
                handleChange={this.handleChange}
                handleCompanySearch={this.handleCompanySearch}
                extraContent={extraContent}
                actualTab={this.handleChangeTab}
                changeSuperiorTab={this.changeSuperiorTab}
                tabValue={selectedTab}
                superiorTabValue={selectedSuperiorTab}
                errors={OrderErrors}
                removeError={asyncRemoveError}
              />
              <Row
                type="flex"
                align="middle"
                justify="end"
                className="orderDetails-header"
              >
                <Col>{extraContent}</Col>
              </Row>
            </div>
          </div>
        </Spin>
      </section>
    );
  }
}

function mapStateToProps(state, props) {
  const id = props.match.params.id;
  return {
    data: extractOrder(state, id),
    configurations: extractOrderConfigurations(state),
    fetchedOrder: fetchedOrder(state, id),
    loading: isFetchingOrder(state),
    resetting: isResettingOrder(state),
    preparing: isPreparingOrder(state),
    saving: isSavingOrder(state),
    selectedTab: getSelectedTab(state),
    OrderErrors: getOrderErrors(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchOrder,
        clearOrderConfigurations,
        resetOrderConfigurations,
        resetOrderStarted,
        saveOrderConfigurations,
        handleOrderChange,
        searchCompany,
        changeTab,
        asyncRemoveError,
        fetchCompanyData,
      },
      dispatch
    ),
  };
}

OrderDetails.defaultProps = {
  fetchOnMount: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
