import React from "react";
import { Route, Switch } from "react-router-dom";
import { CombinedRouter } from "util/router";

import { changeCustomersTab } from "appRedux/actionCreators";
import { useDispatch } from "react-redux";

// redux
import { Provider } from "react-redux";
import store from "../../store";

import asyncComponent from "util/asyncComponent";
import Orders from "containers/Orders/Orders";
import OrderDetails from "containers/Orders/OrderDetails";
import Customers from "containers/Customers";
import Customer from "containers/Customers/Customer";
import ClientAppContainer from "containers/ClientApp";
import ManageClientAppAccess from "containers/ClientApp/ManageClientAppAccess";
import Policy from "containers/Customers/Customer/Policy";
import Invoice from "containers/Customers/Customer/Policy/Invoice";
import orderConfig from "./Orders/config";

// email history
import EmailHistory from "../../containers/Customers/Customer/Policy/EmailHistory";
import PolicyNotificationsHistoryModal from "../../containers/Customers/Customer/Policy/PolicyNotificationsHistoryModal";
import ManageUserAccessModal from "../../components/ClientApp/ManageUserAccessModal";

const Crm = ({ match }) => {
  const dispatch = useDispatch();

  const redirectToCustomerTab = (tab) => {
    dispatch(changeCustomersTab(tab));
  };

  return (
    <Switch>
      <Route
        path={`${match.path}/orders`}
        exact
        component={asyncComponent(() => import("containers/Orders/Orders"))}
      />
      <CombinedRouter
        path={`${match.path}/orders/:id`}
        layout={Orders}
        exact
        component={OrderDetails}
        config={orderConfig}
      />

      <Route
        path={`${match.path}/customers`}
        exact
        component={asyncComponent(() => import("containers/Customers"))}
      />

      <Route
        path={`${match.path}/customers/:id` || `${match.path}/customers/new`}
        render={() => {
          return (
            <Customers>
              <Customer>
                <Route
                  path={
                    `${match.path}/customers/:id/policy/:policy` ||
                    `${match.path}/customers/:id/policy/newpolicy`
                  }
                  render={() => {
                    return (
                      <Provider store={store}>
                        <Policy redirectToCustomerTab={redirectToCustomerTab}>
                          <Route
                            exact
                            path={`${match.path}/customers/:id/policy/:policy/notification-history`}
                          >
                            <PolicyNotificationsHistoryModal />
                          </Route>
                          <Route
                            path={
                              `${match.path}/customers/:id/policy/:policy/invoice/:invoice` ||
                              `${match.path}/customers/:id/policy/:policy/invoice/new`
                            }
                          >
                            <Invoice>
                              <Route
                                exact
                                path={`${match.path}/customers/:id/policy/:policy/invoice/:invoice/email-history`}
                              >
                                <EmailHistory />
                              </Route>
                            </Invoice>
                          </Route>
                        </Policy>
                      </Provider>
                    );
                  }}
                />
              </Customer>
            </Customers>
          );
        }}
      />

      <Route
        path={`${match.path}/client-app`}
        render={() => {
          return (
            <Provider store={store}>
              <ClientAppContainer>
                <ManageClientAppAccess />
                <Route
                  exact
                  path={`${match.path}/client-app/:documentNumber/manage-user-access/:email/:id`}
                >
                  <ManageUserAccessModal />
                </Route>
              </ClientAppContainer>
            </Provider>
          );
        }}
      />
    </Switch>
  );
};

export default Crm;
