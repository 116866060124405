// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../../util/axios";
// translation
import i18n from "../../../../translation/i18n";
// antd
import { message, notification } from "antd";
import { policyEmissionStatusEnum } from "../../../../constants";
import { fetchPolicyActionLinks } from "../../order";

export const policyEmissionSlice = createSlice({
  name: "policyEmissionData",
  initialState: {
    isLoading: false,
    isLoadingEmissionRequest: false,
    formData: {},
    policyData: {},
    paymentSlipsData: [],
    policyEmissionStatus: "",
    slipErrors: {},
    emissionErrorDescription: "",
    policyInsurer: "",
    slipPayload: {
      insurer: "",
      installment: null,
      amount: null,
      dueDate: "",
    },
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsLoadingEmissionRequest: (state, action) => {
      state.isLoadingEmissionRequest = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setPolicyEmissionStatus: (state, action) => {
      state.policyEmissionStatus = action.payload;
    },
    setSlipErrors: (state, action) => {
      state.slipErrors = action.payload;
    },
    setEmissionErrorDescription: (state, action) => {
      state.emissionErrorDescription = action.payload;
    },
    setPolicyData: (state, action) => {
      state.policyData = action.payload;
    },
    setPaymentSlipsData: (state, action) => {
      state.paymentSlipsData = action.payload;
    },
    setPolicyInsurer: (state, action) => {
      state.policyInsurer = action.payload;
    },
    setSlipPayload: (state, action) => {
      state.slipPayload = { ...state.slipPayload, ...action.payload };
    },
  },
});

// ------------------------------------------------------------------

export const {
  setFormData,
  setPolicyEmissionStatus,
  setSlipErrors,
  setIsLoading,
  setEmissionErrorDescription,
  setPolicyData,
  setPaymentSlipsData,
  setIsLoadingEmissionRequest,
  setPolicyInsurer,
  setSlipPayload,
} = policyEmissionSlice.actions;

export default policyEmissionSlice.reducer;

// ------------------------------------------------------------------

export const fetchSuretyPolicyEmissionStatus = (code, documentNumber) => (
  dispatch
) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/surety/v2/${code}/${documentNumber}/policy-by-backoffice`)
      .then((response) => {
        dispatch(setFormData(response?.data?.form));
        dispatch(setPolicyData(response?.data?.form?.policy));
        dispatch(setPaymentSlipsData(response?.data?.form?.paymentSlips));
        dispatch(setPolicyEmissionStatus(response?.data?.status));
        if (response?.data?.status === policyEmissionStatusEnum.ERROR) {
          dispatch(
            setEmissionErrorDescription(response?.data?.errorDescription)
          );
        }
        if (response?.data?.status === policyEmissionStatusEnum.SUCCESS) {
          dispatch(fetchPolicyActionLinks(documentNumber, code));
        }
        resolve(response);
      })
      .catch((error) => {
        message.info(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const requestSuretyPolicyEmission = (code) => (dispatch) => {
  dispatch(setIsLoadingEmissionRequest(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/surety/v2/${code}/policy-by-backoffice`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoadingEmissionRequest(false));
      });
  });
};
