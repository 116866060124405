import React from "react";
//components
import CurrencyInput from "components/CurrencyInput";
import { Row, Col, Radio } from "antd";
//helpers
import { getFormattedDate } from "helpers";

export const PaymentItems = (props) => {
  const { payments } = props;
  return payments.slice()
    .sort((a, b) => a?.installment - b?.installment)
    .map((payment) => {
      if (payment) {
        return (
          <Radio
            key={Math.random()}
            className="payment-item"
            value={payment.installment}
          >
            <Row gutter={12}>
              <Col sm={9} md={9}>
                <b style={{ textTransform: "uppercase" }}>
                  {payment?.description}
                </b>
                <br />
                {payment.installment}
                {payment.installment === 1 ? " parcela" : " parcelas"}
              </Col>
              <Col sm={5} md={5} className="text-center">
                {payment.othersInstallmentAmount ? (
                  <CurrencyInput
                    value={payment.othersInstallmentAmount}
                    displayType="text"
                  />
                ) : (
                  <CurrencyInput
                    value={payment.firstInstallmentAmount}
                    displayType="text"
                  />
                )}
              </Col>
              <Col sm={5} md={5} className="text-center">
                {getFormattedDate(payment.firstInstallmentPaymentDate)}
              </Col>
              <Col sm={5} md={5} className="text-center">
                <CurrencyInput value={payment.total} displayType="text" />
              </Col>
            </Row>
          </Radio>
        );
      }

      return null;
    });
};
