export function storeUser(user) {
  return localStorage.setItem("user_id", JSON.stringify(user));
}

export function getStoredUser() {
  return JSON.parse(localStorage.getItem("user_id"));
}

export function removeStoredUser() {
  return localStorage.removeItem("user_id");
}

export function storeSessionStart(user) {
  return localStorage.setItem("login_time", JSON.stringify(user));
}

export function getSessionStart() {
  return JSON.parse(localStorage.getItem("login_time"));
}

export function removeSessionStart() {
  return localStorage.removeItem("login_time");
}
